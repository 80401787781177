<!--
 * @Module 导航组件
 -->
<template>
  <div>
    <div v-if="isPc" class="navber">
      <div class="navber-position">
        <img src="~@/assets/images/common/icon_location.png" alt="" />
        <div>当前位置：</div>
        <div v-for="(item, index) in data" :key="index" class="navItem">
          <div :class="index !== data.length - 1 ? '' : 'unique'" @click="navberClick(item, index)">
            {{ item.categoryTitle }}
          </div>
          <div v-show="index !== data.length - 1" class="connector">></div>
        </div>
      </div>
    </div>

    <div v-else class="navber-wap">
      <div class="navber-wap-position">
        <div v-if="isPc">您的位置：</div>
        <div v-for="(item, index) in data" :key="index" class="navItem">
          <div :class="index !== data.length - 1 ? '' : 'unique'" @click="navberClick(item, index)">
            {{ getBeyondHidingText(item.categoryTitle) }}
          </div>
          <div v-show="index !== data.length - 1" class="connector">{{isPc ? '/' : '>'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--JavaScript-->
<script>
import { mapState } from 'vuex'
export default {
  name: 'Navber',
  props: {
    data: {
      type: Array,
      default: () => ({})
    }
  },
  methods: {
    navberClick(item, index) {
      if (index !== this.data.length - 1) {
        this.$emit('navbarChange', item)
      }
    },
    getBeyondHidingText(title) {
      let str = title
      if (str.length > 8 && !this.isPc) {
        str = str.slice(0, 8) + '...'
      }
      return str
    }
  },
  computed: {
    ...mapState(['isPc'])
  }
}
</script>
<!--CSS-PAGE-->
<style lang="scss" scoped>
.navber {
  display: flex;
  align-items: center;

  &-position {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #999999;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .navItem {
      cursor: pointer;
      display: flex;
      align-items: center;

      > div {
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .connector {
        margin: 0.03rem 0.05rem 0;
        font-size: 0.21rem;
      }

      .unique {
        color: #409eff;
        // color: #00357e;
        cursor: default;
      }
    }
  }
}

.navber-wap {
  display: flex;
  align-items: center;
  border-bottom: 0.01rem solid #e2e2e2;
  padding: 0 0 0.04rem;

  &-position {
    display: flex;
    align-items: center;
    margin-left: 0.05rem;
    font-size: 0.12rem;
    line-height: 0.18rem;
    color: #999999;

    .navItem {
      cursor: pointer;
      display: flex;
      align-items: center;

      > div {
        max-width: 1.5rem;
        padding-top: 2px;
      }

      .connector {
        margin: 0 0.05rem 0.01rem;
        font-size: 0.18rem;
      }

      .unique {
        color: #409eff;
        // color: #00357e;
        cursor: default;
      }
    }
  }
}
</style>
