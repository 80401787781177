export default {
  data() {
    return {}
  },
  methods: {
    /**
     * 导航跳转
     */
    async navbarChange(data) {
      const { level, categoryId, id, isLeaf, categoryTitle } = data

      if (categoryTitle === '首页') {
        this.naviGate('index', { t: new Date().getTime() })
      } else {
        // 点击不同层级导航
        if (level === 1) {
          // 是否叶子节点栏目(0:列表页 1:详情页)
          if (isLeaf === 1) {
            // 详情页
            this.naviGate('detail', { t: new Date().getTime(), contentId: id })
          } else {
            // 列表页
            if (!categoryId || categoryId === '') {
              // 代表为导航回到一级菜单 (默认打开第一级子菜单内容)

              // 获取本一级菜单以及子菜单的详情数据 为tree结构
              const manyMenu = this.menuList
                .filter((item) => item.id === id)
                .pop()

              // 一级菜单点击 有子菜单 默认打开第一个子菜单数据
              if (manyMenu.child && manyMenu.child.length > 0) {
                // 是否叶子节点栏目(0:列表页 1:详情页) 当子菜单下数据列表为1条时跳过列表页 直接进入详情页
                if (manyMenu.child[0].isLeaf === 1) {
                  // 详情页
                  try {
                    if (!manyMenu.child[0].id) {
                      console.log('id is null')
                      return
                    }
                    const { errCode, data } = await this.api.post(
                      '/webclient/getContentList',
                      {
                        categoryId: manyMenu.child[0].id,
                        contentDisplay: false,
                        orders: [
                          // { column: 'contentSort', asc: true },
                          { column: 'contentTime', asc: false },
                          { column: 'id', asc: false }
                        ]
                      }
                    )
                    if (!errCode) {
                      const contentId = data.records[0].id
                      this.naviGate('detail', {
                        t: new Date().getTime(),
                        contentId
                      })
                    }
                  } catch (error) {
                    console.log(error)
                  }
                } else {
                  // 列表页
                  this.naviGate('list', {
                    t: new Date().getTime(),
                    categoryId: manyMenu.child[0]
                      ? manyMenu.child[0].categoryId
                      : '',
                    id: manyMenu.child ? manyMenu.child[0].id : ''
                  })
                }
              } else {
                // 一级菜单点击 无子菜单 直接打开数据

                // 是否叶子节点栏目(0:列表页 1:详情页) 当子菜单下数据列表为1条时跳过列表页 直接进入详情页
                if (manyMenu.isLeaf === 1) {
                  // 详情页
                  try {
                    if (!manyMenu.id) {
                      console.log('id is null')
                      return
                    }
                    const { errCode, data } = await this.api.post(
                      '/webclient/getContentList',
                      {
                        categoryId: manyMenu.child[0].id,
                        contentDisplay: false,
                        orders: [
                          // { column: 'contentSort', asc: true },
                          { column: 'contentTime', asc: false },
                          { column: 'id', asc: false }
                        ]
                      }
                    )
                    if (!errCode) {
                      const contentId = data.records[0].id
                      this.naviGate('detail', {
                        t: new Date().getTime(),
                        contentId
                      })
                    }
                  } catch (error) {
                    console.log(error)
                  }
                } else {
                  // 列表页
                  this.naviGate('list', {
                    t: new Date().getTime(),
                    categoryId: manyMenu.categoryId,
                    id: manyMenu.id
                  })
                }
              }
            } else {
              this.naviGate('list', {
                t: new Date().getTime(),
                categoryId: categoryId,
                id: id
              })
            }
          }
        } else if (level === 2) {
          // 是否叶子节点栏目(0:列表页 1:详情页)
          if (isLeaf === 1) {
            // 详情页 此时列表只有一条 不展示列表 直接打开详情页
            try {
              if (!id) {
                console.log('id is null')
                return
              }
              const { errCode, data } = await this.api.post(
                '/webclient/getContentList',
                {
                  categoryId: id,
                  contentDisplay: false,
                  orders: [
                    // { column: 'contentSort', asc: true },
                    { column: 'contentTime', asc: false },
                    { column: 'id', asc: false }
                  ]
                }
              )
              if (!errCode) {
                const contentId = data.records[0].id
                this.naviGate('detail', { t: new Date().getTime(), contentId })
              }
            } catch (error) {
              console.log(error)
            }
          } else {
            // 列表页
            this.naviGate('list', {
              t: new Date().getTime(),
              categoryId: categoryId,
              id: id
            })
          }
        }
      }
    },
    /**
     * 左侧栏目列表点击
     */
    async handleCategoryList(row) {
      const { categoryDiyUrl, categoryId, id, isLeaf } = row

      if (categoryDiyUrl) {
        // 外链
        window.open(categoryDiyUrl)
      } else {
        // 内链
        // 是否叶子节点栏目(0:列表页 1:详情页)
        if (isLeaf === 1) {
          // 详情页 此时列表只有一条 不展示列表 直接打开详情页
          try {
            if (!id) {
              console.log('id is null')
              return
            }
            const { errCode, data } = await this.api.post(
              '/webclient/getContentList',
              {
                categoryId: id,
                contentDisplay: false,
                orders: [
                  // { column: 'contentSort', asc: true },
                  { column: 'contentTime', asc: false },
                  { column: 'id', asc: false }
                ]
              }
            )
            if (!errCode) {
              if (data.records && data.records.length > 0) {
                const {
                  records: [{ id: contentId, contentDisplay }]
                } = data
                if (contentDisplay && contentDisplay !== 'hidden') {
                  this.naviGate('detail', {
                    t: new Date().getTime(),
                    contentId
                  })
                } else {
                  this.naviGate('list', {
                    t: new Date().getTime(),
                    categoryId,
                    id
                  })
                }
              } else {
                this.naviGate('list', {
                  t: new Date().getTime(),
                  categoryId,
                  id
                })
              }
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          // 列表页
          this.naviGate('list', {
            t: new Date().getTime(),
            categoryId,
            id
          })
        }
      }
    }
  },
  filters: {}
}
