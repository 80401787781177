<!--
 * @Module 内容或列表的左侧菜单
 -->
<template>
  <div class="left-menu">
    <div :class="[showFixedMenu ? 'position_box' : '']">
      <div class="menu_title">
        {{ categoryTitle }}
      </div>

      <div class="menu_list" v-show="subMenuList.length > 0">
        <div v-for="(item, index) in subMenuList" :key="index" class="menu_item">
          <div :class="[item.checked ? 'active' : '', 'link']" @click="handleList(item, index)">
            <!-- <div class="column_icon">
              <img v-if="item.checked" src="@/assets/images/pc/icon_column_list.png" alt="">
            </div> -->
            <div>{{ item.categoryTitle }}</div>

            <div class="column_icon">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>

          <div v-if="item.child" :style="{maxHeight: item.select ? '1000px' : '0', borderWidth: item.select ? '1px' : '0' }" :ref="`child${item.id}`" class="menu_child_box">
            <div v-for="(obj, i) in item.child" :key="i" @click="handleList(obj, i)" class="menu_child_item">
              <div :class="[obj.checked ? 'active' : '', i == item.child.length - 1 ? '' : 'border_dashed', 'child_link']">
                {{ obj.categoryTitle }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide',
  props: {
    categoryTitle: {
      type: String
    },
    subMenuList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showFixedMenu: false
    }
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener('scroll', this.showMenu)
  },
  destroyed() {
    window.removeEventListener('scroll', this.showMenu, true)
  },
  methods: {
    /**
     * @description: 页面滚动监听
     */
    showMenu() {
      // 获取当前滚动条向下滚动的距离
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 490) {
        this.showFixedMenu = true
      } else {
        this.showFixedMenu = false
      }
    },
    handleList(item, index) {
      this.$emit('handleLeftMenu', item)
    }
  }
}
</script>

<style lang="scss" scoped>
.left-menu {
  width: 256px;
  flex-shrink: 0;
  position: relative;

  .position_box {
    width: 256px;
    position: fixed;
    top: 60px;
  }

  .menu_title {
    width: 100%;
    height: 60px;
    background: rgba(14, 89, 174, 1);
    font-size: 22px;
    font-weight: bold;
    color: #ffffff;
    padding-left: 24px;
    line-height: 60px;
    position: absolute;
    left: 0;
    top: -60px;
  }

  .menu_list {
    width: 100%;
    background-color: #fafafa;

    .menu_item {
      width: 100%;

      .link {
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        color: #333;
        padding: 0 18px 0 24px;
        /* margin-top: 4px; */
        background-color: #fafafa;
        border-bottom: 1px solid #ececec;
        cursor: pointer;

        .column_icon {
          font-size: 14px;
          color: #999999;
        }

        &.active {
          color: #409eff !important;
          background: #f1f1f1;
        }
      }

      .menu_child_box {
        width: 100%;
        border-bottom: 1px solid #ececec;
        transition: max-height 0.5s ease;
        overflow: hidden;

        .menu_child_item {
          width: 100%;
          background: #fafafa;
          padding: 0 24px;

          .child_link {
            width: 100%;
            height: 64px;
            line-height: 64px;
            font-size: 16px;
            color: #333;
            padding-left: 30px;
            cursor: pointer;

            &.active {
              color: #409eff !important;
            }
          }

          .border_dashed {
            border-bottom: 1px dashed #ececec;
          }
        }
      }
    }
  }
}
</style>
